import React from 'react'

function PahrmaProfile() {
  return (
    <div>
      PahrmaProfile
    </div>
  )
}

export default PahrmaProfile
