import React from 'react'

function ResearchProfile() {
  return (
    <div>
      ResearchProfile
    </div>
  )
}

export default ResearchProfile
