import React from 'react'
import Navbar from '../../../components/NavigationBar';
import Footer from '../../../components/Footer';
function Profile() {
  return (
    <div>
        <Navbar/>
      profile
      <Footer/>
    </div>
  )
}

export default Profile
